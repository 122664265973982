import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { compareUsers } from "../../../actions/chatUsersAction";
import offers from "../../../offers.json";

import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import filtersTranslate from '../../../components/filterstranslate.json';
import translate from './translate.json';
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import { Line } from "react-chartjs-2";
import { Kanban } from "../../../components/customComponents/Kanban";
import { headerColors } from "../../../modules/leadModule";
import { animateBox } from "../../../modules/componentAnimation";
import publishers from "../WeeklyReportPubs/publishers.json";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { ArcElement } from 'chart.js';
import { registerables } from 'chart.js';
import { object } from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as timestampActions from "../../../actions/timestampAction";


const DealReport = () => {
    const [searchParams] = useSearchParams();
    const dealSaveStatusCodes = useSelector(state => state?.statusCodes?.dealSaves ?? null);
    const dealStatusSelector = useSelector(state => state?.statusCodes?.deals ?? {});
    const chatUsers = useSelector((state) => state.chatUsers ?? [], compareUsers);
    const [countries, setCountries] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [deals, setDeals] = React.useState()
    const [offerScores, setOfferScores] = React.useState();
    const [avgCPAO, setAvgCPAO] = React.useState(0);
    const [prosjek, setProsjek] = React.useState(0)
    const [dr, setDr] = React.useState(0);
    const [revenueDelivered, setRevenueDelivered] = React.useState(0)
    const [total, setTotal] = React.useState(0);
    const [dealStatuses, setDealStatuses] = React.useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [spinner, setSpinner] = React.useState(false);
    const [date, setDate] = React.useState(0);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [agents, setAgents] = React.useState();
    const [agentsWD, setAgentsWD] = React.useState();
    let language = useSelector(state => state.language);
    const [sorting, setSorting] = React.useState("broj");
    const [callCenters, setCallCenters] = React.useState(null);
    const [selectedcc, setSelectedcc] = React.useState();
    const [pickerActive, setPickerActive] = React.useState();
    const [selectedPeriod, setSelectedPeriod] = React.useState([
        {
            startDate: moment().startOf("day").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection"
        }
    ]);
    const [currentDay, setCurrentDay] = React.useState(moment().format("DD/MM/YYYY"));

    const [pubID, setpubID] = React.useState([]);
    const [dealHours, setDealHours] = React.useState();
    const [callcentersData, setCallcentersData] = React.useState();
    const [activeChart, setActiveChart] = React.useState("total");
    const [type, setType] = React.useState();
    const [subtype, setSubtype] = React.useState();
    const [callTime, setCallTime] = React.useState();
    const [noDataMsg, setNoDataMsg] = React.useState();
    const [timeLeft, setTimeLeft] = React.useState(300);
    const [timerUpdate, setTimerUpdate] = React.useState();
    const username = searchParams.get('username');
    const password = searchParams.get('password');

    let userDataDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    const timestamp = useSelector((state) => state.timeStamp);


    const userLogin = (e) => {
        if (userData.isLoggedIn) {
            return;
        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data: {
                username: username,
                password: password,
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                userDataDispatch(timestampActions.updateTimestamp());
            } else {

            }
        }).catch((e) => {

        });
    }
    React.useEffect(() => {
        if (!username && !password) return;
        userLogin();
    }, [username, password])

    React.useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    setTimerUpdate(Math.random())
                    if (moment().format("DD/MM/YYYY") !== currentDay) {
                        setSelectedPeriod([
                            {
                                startDate: moment().startOf("day").toDate(),
                                endDate: moment().endOf("day").toDate(),
                                key: "selection"
                            }
                        ])
                    }
                    return 300;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    ChartJS.register(ArcElement);
    ChartJS.register(...registerables);


    React.useEffect(() => {
        if (!userData.isLoggedIn) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let tmp = [{ name: translate[language].SveDrzave, value: null }]
                res.data.data?.map(country => {
                    tmp.push({ name: country.CountryName, value: country.CountryCode })
                })
                return setCountries(tmp);
            }
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        });
    }, [userData.isLoggedIn]);
    const getWorkSchedule = async (agentsComplete, agentsIDs) => {
        let finalAgents = agentsComplete;
        let finalIDs = agentsIDs;
        let agentWorkingDays = {};
        let date = moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours");
        while (moment(selectedPeriod[0].endDate).add(1, "days").isAfter(date)) {
            let tmpMonth = moment(date).format("MM YYYY");
            let tmpDay = Number(moment(date).format("DD"));
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/workSchedule/getWorkScheduleAll`,
                data: {
                    Month: tmpMonth,
                    Day: tmpDay
                },
                ...backendModule.axiosConfig
            }).then(res => {

                for (let agt of res.data.data) {
                    let curUser = chatUsers.find(usr => usr.ID === agt[0]);
                    let finalUser = curUser?.FirstName + " " + curUser?.LastName

                    if (!agentWorkingDays[finalUser]) {
                        agentWorkingDays[finalUser] = [];
                    }
                    agentWorkingDays[finalUser].push(tmpDay);
                    if (selectedcc === null || selectedcc === agt[1]) {
                        if (!finalIDs.includes(agt[0])) {
                            finalIDs.push(agt[0]);
                            finalAgents[finalUser] = [0, agt[1], 0, []]
                        }
                    } else {
                    }

                }
                date = date.add(1, "days");


            }).catch((e) => {
            });
        }
        setTimeout(() => {
            setAgents(finalAgents)
            calculateAveragePerAgent(finalAgents, agentWorkingDays)
        }, 1000)

    }



    const getDeals = () => {
        setSpinner(true)
        let tmpDate = [];
        if (moment(selectedPeriod[0].startDate).format("DD/MM/YYYY") === moment(selectedPeriod[0].endDate).format("DD/MM/YYYY")) {
            tmpDate = [{ name: "createdAt", op: "deq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() }]
        } else {
            tmpDate = [{ name: "createdAt", op: "dgeq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() },
            { name: "createdAt", op: "dleq", value: moment(selectedPeriod[0].endDate).endOf("day").add(-5, "hours").toDate() }]
        }


        let leadType = [];
        if (type === "new") {
            leadType = [
                { name: "ResponsiblePersonBeforeReject", op: "eq", value: null },
                { name: "isRecurring", op: "eq", value: 0 }
            ]
        } else if (type === "rec") {
            leadType = [
                { name: "isRecurring", op: "eq", value: 1 }
            ]
        } else if (type === "dod") {
            leadType = [
                { name: "SubType", op: "eq", value: "dodatno-oboljenje" }
            ]
        }
        else if (type === "zad") {
            leadType = [
                { name: "ResponsiblePersonBeforeReject", op: "neq", value: null },
            ]
        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDealReport`,
            data: {
                pagination: null,
                SubType: subtype,
                filters: [
                    ...filters,
                    ...tmpDate,
                    ...leadType,
                    ...pubID,
                    selectedCountry ? {
                        name: "Country",
                        op: "eq",
                        value: selectedCountry
                    } : null
                ]
            },
            ...backendModule.axiosConfig
        }).then(async res => {

            if (res.data.status === 'ok') {
                if (res.data.data.length === 0) {
                    setNoDataMsg("Nisu Pronadjeni dealovi sa datim filterima, molimo pokusajte koristiti druge filtere");
                } else {
                    setNoDataMsg();
                }
                let hours = [
                    ["0-1", 0, 0],
                    ["1-2", 0, 0],
                    ["2-3", 0, 0],
                    ["3-4", 0, 0],
                    ["4-5", 0, 0],
                    ["5-6", 0, 0],
                    ["6-7", 0, 0],
                    ["7-8", 0, 0],
                    ["8-9", 0, 0],
                    ["9-10", 0, 0],
                    ["10-11", 0, 0],
                    ["11-12", 0, 0],
                    ["12-13", 0, 0],
                    ["13-14", 0, 0],
                    ["14-15", 0, 0],
                    ["15-16", 0, 0],
                    ["16-17", 0, 0],
                    ["17-18", 0, 0],
                    ["18-19", 0, 0],
                    ["19-20", 0, 0],
                    ["20-21", 0, 0],
                    ["21-22", 0, 0],
                    ["22-23", 0, 0],
                    ["23-24", 0, 0],
                ]
                let sum = 0
                let tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                let agentsTmp = {};
                let agentsIDs = [];
                let totalCount = 0;
                let callCentersd = {};
                let finalRevenueDelivered = 0;
                for (let i = 0; i < res.data.data.length; i++) {
                    if (selectedcc === null || selectedcc === res.data.data[i].CC) {

                        let dealAmount = 0;
                        if (res.data.data[i].Country === 'BA') {
                            dealAmount = Number(res.data.data[i].Amount) * 0.51
                        } else if (res.data.data[i].Country === 'RS') {
                            dealAmount = Number(res.data.data[i].Amount) * 0.0085
                        } else if (res.data.data[i].Country === 'RO') {
                            dealAmount = Number(res.data.data[i].Amount) * 0.2
                        } else {
                            dealAmount = Number(res.data.data[i].Amount)
                        }

                        if (dealAmount >= 1) {
                            totalCount += 1;

                            //calculating hours
                            if (Number(moment(res.data.data[i].createdAt).format("HH")) < 0) {
                                hours[0][1] += 1;
                                hours[0][2] += dealAmount;
                            } else {
                                hours[Number(moment(res.data.data[i].createdAt).format("HH"))][1] += 1;
                                hours[Number(moment(res.data.data[i].createdAt).format("HH"))][2] += dealAmount;
                            }
                            //cc data
                            if (!callCentersd[res.data.data[i].CC]) callCentersd[res.data.data[i].CC] = [
                                ["0-1", 0, 0],
                                ["1-2", 0, 0],
                                ["2-3", 0, 0],
                                ["3-4", 0, 0],
                                ["4-5", 0, 0],
                                ["5-6", 0, 0],
                                ["6-7", 0, 0],
                                ["7-8", 0, 0],
                                ["8-9", 0, 0],
                                ["9-10", 0, 0],
                                ["10-11", 0, 0],
                                ["11-12", 0, 0],
                                ["12-13", 0, 0],
                                ["13-14", 0, 0],
                                ["14-15", 0, 0],
                                ["15-16", 0, 0],
                                ["16-17", 0, 0],
                                ["17-18", 0, 0],
                                ["18-19", 0, 0],
                                ["19-20", 0, 0],
                                ["20-21", 0, 0],
                                ["21-22", 0, 0],
                                ["22-23", 0, 0],
                                ["23-24", 0, 0],
                            ]
                            if (Number(moment(res.data.data[i].createdAt).format("HH")) < 0) {
                                callCentersd[res.data.data[i].CC][0][1] += 1;
                                callCentersd[res.data.data[i].CC][0][2] += dealAmount;
                            } else {
                                callCentersd[res.data.data[i].CC][Number(moment(res.data.data[i].createdAt).format("HH"))][1] += 1;
                                callCentersd[res.data.data[i].CC][Number(moment(res.data.data[i].createdAt).format("HH"))][2] += dealAmount;
                            }



                            if (!agentsIDs.includes(res.data.data[i].ResponsiblePerson)) {
                                agentsIDs.push(res.data.data[i].ResponsiblePerson)
                            }

                            if (!agentsTmp[res.data.data[i].ResponsiblePersonName]) agentsTmp[res.data.data[i].ResponsiblePersonName] = [0, res.data.data[i].CC, 0, [], [
                                ["0-1", 0, 0],
                                ["1-2", 0, 0],
                                ["2-3", 0, 0],
                                ["3-4", 0, 0],
                                ["4-5", 0, 0],
                                ["5-6", 0, 0],
                                ["6-7", 0, 0],
                                ["7-8", 0, 0],
                                ["8-9", 0, 0],
                                ["9-10", 0, 0],
                                ["10-11", 0, 0],
                                ["11-12", 0, 0],
                                ["12-13", 0, 0],
                                ["13-14", 0, 0],
                                ["14-15", 0, 0],
                                ["15-16", 0, 0],
                                ["16-17", 0, 0],
                                ["17-18", 0, 0],
                                ["18-19", 0, 0],
                                ["19-20", 0, 0],
                                ["20-21", 0, 0],
                                ["21-22", 0, 0],
                                ["22-23", 0, 0],
                                ["23-24", 0, 0],
                            ]]
                            agentsTmp[res.data.data[i].ResponsiblePersonName][0]++
                            sum += dealAmount;
                            agentsTmp[res.data.data[i].ResponsiblePersonName][2] += dealAmount;
                            agentsTmp[res.data.data[i].ResponsiblePersonName][3].push(res.data.data[i].ID);
                            if (Number(moment(res.data.data[i].createdAt).format("HH")) < 0) {
                                agentsTmp[res.data.data[i].ResponsiblePersonName][4][0][1] += 1;
                                agentsTmp[res.data.data[i].ResponsiblePersonName][4][0][2] += dealAmount;
                            } else {
                                agentsTmp[res.data.data[i].ResponsiblePersonName][4][Number(moment(res.data.data[i].createdAt).format("HH"))][1] += 1;
                                agentsTmp[res.data.data[i].ResponsiblePersonName][4][Number(moment(res.data.data[i].createdAt).format("HH"))][2] += dealAmount;
                            }

                            if (res.data.data[i].Status === 0) {
                                tmp[0]++
                            } else if (res.data.data[i].Status === 1) {
                                tmp[1]++
                            } else if (res.data.data[i].Status === 2) {
                                tmp[2]++
                            } else if (res.data.data[i].Status === 3) {
                                tmp[3]++
                            } else if (res.data.data[i].Status === 49) {
                                tmp[4]++
                            } else if (res.data.data[i].Status === 50) {
                                tmp[5]++
                            } else if (res.data.data[i].Status === 98) {
                                tmp[6]++
                            } else if (res.data.data[i].Status === 99) {
                                tmp[7]++
                            } else if (res.data.data[i].Status === 100) {
                                tmp[8]++;
                                finalRevenueDelivered += dealAmount;
                            } else if (res.data.data[i].Status === 5000) {
                                tmp[9]++
                            } else if (res.data.data[i].Status === 4) {
                                tmp[10]++;
                            };

                        }
                    }

                }
                // setAgents(agentsTmp)
                if (totalCount > 0) {
                    setDeals(totalCount)
                    setProsjek(sum / totalCount)
                    setDealStatuses(tmp)
                    setTotal(sum)
                    setDealHours(hours);
                    setCallcentersData(callCentersd)
                    getWorkSchedule(agentsTmp, agentsIDs);

                    // let finalDr = 100 / (tmp[0] + tmp[1] + tmp[2] + tmp[10] + tmp[6] + tmp[7] + tmp[8]) * tmp[8];
                    let finalDr = 100 / totalCount * tmp[8];
                    if (isNaN(finalDr)) finalDr = 0;
                    setDr(finalDr);
                    setRevenueDelivered(finalRevenueDelivered);
                };

            }
        }).catch((e) => { console.log(e) }).finally(() => { setSpinner(false) })

    }


    const getOfferScores = () => {
        let tmpDate = [];
        if (moment(selectedPeriod[0].startDate).format("DD/MM/YYYY") === moment(selectedPeriod[0].endDate).format("DD/MM/YYYY")) {
            tmpDate = [{ name: "createdAt", op: "deq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() }]
        } else {
            tmpDate = [{ name: "createdAt", op: "dgeq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() },
            { name: "createdAt", op: "dleq", value: moment(selectedPeriod[0].endDate).endOf("day").add(-5, "hours").toDate() }]
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/getOfferScore`,
            data: {
                filters: [...tmpDate]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOfferScores(res.data.data);
            };
        }).catch(() => null);
    };

    const getAvgCPAO = () => {
        let tmpDate = [];
        if (moment(selectedPeriod[0].startDate).format("DD/MM/YYYY") === moment(selectedPeriod[0].endDate).format("DD/MM/YYYY")) {
            tmpDate = [{ name: "Date", op: "deq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() }]
        } else {
            tmpDate = [{ name: "Date", op: "dgeq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() },
            { name: "Date", op: "dleq", value: moment(selectedPeriod[0].endDate).endOf("day").add(-5, "hours").toDate() }]
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/getAvgCPAO`,
            data: {
                filters: [...tmpDate]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAvgCPAO(res.data.data);
            };
        }).catch(() => null);
    };

    const calculateAveragePerAgent = (ag, wd) => {
        if (!ag) return;
        if (!wd) return;
        let agentsTmp = {};
        for (let agt of Object.keys(ag)) {
            agentsTmp[agt] = [ag?.[agt][0] ?? 0, wd?.[agt] ? wd?.[agt]?.length : 0, ag?.[agt][2] ?? 0];
        }
        setAgentsWD(agentsTmp)
    }




    const getCallDuration = () => {
        let additionalFilters = [];


        additionalFilters.push({ name: "createdAt", op: "dgeq", value: moment(selectedPeriod[0].startDate).startOf("day").add(5, "hours").toDate() });
        additionalFilters.push({ name: "createdAt", op: "dleq", value: moment(selectedPeriod[0].endDate).startOf("day").add(5, "hours").toDate() });


        axios({
            method: "POST",
            url: `${backendModule.backendURL}/callRecordings/getRecordingStats_short`,
            data: {
                filters: [
                    ...additionalFilters
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            let tmp = []
            if (res.data.status === "ok") {
                for (let single of res.data.data) {
                    let curUser = chatUsers.find(usr => usr.ID === single.UserID);
                    tmp.push({ ...single, Username: curUser?.FirstName + " " + curUser?.LastName })

                }
                setCallTime({ status: "ok", data: tmp });
            } else {
                setCallTime(backendModule.genericError);
            };
        }).catch(() => {
            setCallTime(backendModule.genericError);
        });
    };






    const handleOnChange = (ranges) => {
        // const { selection } = ranges;
        // onChange(selection);
        // setSelectedPeriod([selection]);
        let finalDates = ranges.selection
        if (moment(ranges?.selection?.endDate).isAfter(moment())) {
            finalDates.endDate = moment();
        } else {
            finalDates.endDate = ranges?.selection?.endDate
        }
        if (ranges?.selection?.startDate !== ranges?.selection?.endDate) {
            setPickerActive(false)
        }
        setSelectedPeriod([finalDates]);
    };

    React.useEffect(() => {
        if (!selectedPeriod) return;
        if (!callCenters) return;
        if (!userData.isLoggedIn) return;
        getDeals()
        getOfferScores();
        getAvgCPAO();
        getCallDuration()
    }, [filters, date, selectedCountry, selectedPeriod, selectedcc, type, callCenters, subtype, pubID, timerUpdate, userData.isLoggedIn])

    React.useEffect(() => {
        if (!userData.isLoggedIn) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllCallCentars`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCallCenters(res.data.data);
        }).catch(() => {
        });
    }, [userData.isLoggedIn])

    if (!userData.isLoggedIn) return <div></div>

    return <div className="component__dealReport">

        <div className="component__dealReport__reports__filters">
            <p>Deal report <br /> <small style={{ fontSize: "12px" }}>Update za : {formatTime(timeLeft)}</small> </p>
            <div className="component__dealReport__reports__filters__drops">
                {countries.length > 0 && <Dropdown theme='dark' style={{
                    borderRadius: "50px",
                    backgroundColor: "#2e2e41",
                    boxShadow: "none"
                }} data={countries}
                    selected={(() => {
                        let country = countries.find(c => c.value === selectedCountry?.value);
                        if (!country) return 0;
                        return countries.indexOf(country);
                    })()}
                    onChange={(e) => {
                        setSelectedCountry(e?.value)
                    }} />}
                {callCenters && <Dropdown theme={"dark"} selected={0} style={{
                    borderRadius: "50px",
                    backgroundColor: "#2e2e41",
                    boxShadow: "none"
                }}
                    accent={"#141518"} data={
                        [{ name: "All agents", value: null }, ...callCenters.map(item => {
                            return { name: item, value: item }
                        })]} onChange={(e) => {
                            setSelectedcc(e.value)

                        }} />}
                <Dropdown theme={"dark"} selected={0} style={{
                    borderRadius: "50px",
                    backgroundColor: "#2e2e41",
                    boxShadow: "none"
                }}
                    accent={"#141518"} data={
                        [{ name: "All leads", value: null },
                        { name: "Novi upit", value: "new" },
                        { name: "Produzenja", value: "rec" },
                        { name: "Dodatno oboljenje", value: "dod" },
                        { name: "Zadnja sansa", value: "zad" },



                        ]} onChange={(e) => {
                            setType(e.value)

                        }} />

                <Dropdown theme={"dark"} selected={0} style={{
                    borderRadius: "50px",
                    backgroundColor: "#2e2e41",
                    boxShadow: "none"
                }}
                    accent={"#141518"} data={
                        [
                            { name: "All types", value: null },
                            { name: "Paid", value: "paid" },
                            { name: "Valnor", value: "valnor" },
                            { name: "Glovia", value: "glovia" },
                            { name: "Free", value: "free" },
                            { name: "Promo", value: "promo" },
                            { name: "Cross sell", value: "cross" },
                            { name: "Not Cross sell", value: "nocross" },


                        ]} onChange={(e) => {
                            setSubtype(e.value)

                        }} />

                <div className="component__dealReport__reports__filters__rangePicker" >
                    <p onClick={() => { setPickerActive(!pickerActive) }}>{selectedPeriod ? `Selected Period: ${moment(selectedPeriod[0].startDate).format("DD.MM.YYYY")} - ${moment(selectedPeriod[0].endDate).format("DD.MM.YYYY")}`
                        : "Nije izabran period"}</p>
                    <div style={{ maxHeight: pickerActive ? "1000px" : "0px", zIndex: "999" }}>
                        <DateRangePicker
                            onChange={handleOnChange}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={selectedPeriod}
                            direction="horizontal"
                        // maxDate={new Date()}
                        />
                    </div>

                </div>
                {publishers && <Dropdown style={{ borderRadius: "26px" }} selected={0} theme={"dark"}
                    accent={"#141518"} data={publishers.map((item, index) => {
                        return { name: item[0], value: item[1] ?? item[0], index }
                    })} onChange={(e) => {
                        if (e.index === 0) {
                            setpubID([])
                        } else {
                            setpubID([{ name: 'AdditionalData:PublisherID', op: 'eq', value: e.value }])
                        }
                    }} />}
            </div>

            <FilterPanel
                style={{ backgroundColor: "#2b2c3e" }}
                accent="#6664E5"
                theme="dark"
                filterCB={fi => setFilters(fi)}
                filters={[
                    { name: "ID", friendlyName: filtersTranslate[language]["ID"], type: "string" },
                    { name: "LeadID", friendlyName: "Lead ID", type: "string" },
                    { name: "Name", friendlyName: filtersTranslate[language].Nazivdeala, type: "string" },
                    {
                        name: "Status", friendlyName: filtersTranslate[language].Statusdeala, type: "custom", varType: "number", data: Object.keys(dealStatusSelector).map(dealStatusKey => {
                            return { text: dealStatusSelector[dealStatusKey], value: dealStatusKey }
                        })
                    },
                    { name: "Amount", friendlyName: filtersTranslate[language]["Cijena"], type: "number" },
                    { name: "FirstLastName", friendlyName: filtersTranslate[language]["Ime i prezime kupca"], type: "string" },
                    { name: "PhoneNumber", friendlyName: filtersTranslate[language]["Broj telefona kupca"], type: "string" },
                    { name: "Address", friendlyName: filtersTranslate[language]["Adresa kupca"], type: "string" },
                    { name: "City", friendlyName: filtersTranslate[language]["Grad kupca"], type: "string" },
                    {
                        name: "Country", friendlyName: filtersTranslate[language]["Država kupca"], type: "custom", varType: "string", data: countries.filter(t => t.value !== null).map(t => {
                            return { text: t.name, value: t.value }
                        })
                    },
                    {
                        name: "ResponsiblePerson", friendlyName: filtersTranslate[language]["Odgovorni agent"], type: "custom", varType: "number", data: chatUsers.filter(t => t.value !== null).map(t => {
                            return { text: t.FirstName + ' ' + t.LastName, value: t.ID }
                        })
                    },
                    {
                        name: "DealResponsiblePerson", friendlyName: filtersTranslate[language].Odgovorniagentzadeal, type: "custom", varType: "number", data: chatUsers.filter(t => t.value !== null).map(t => {
                            return { text: t.FirstName + ' ' + t.LastName, value: t.ID }
                        })
                    },
                    { name: "HouseNumber", friendlyName: filtersTranslate[language]["Broj kuće kupca"], type: "string" },
                    // {
                    //     name: "DealSaveStatus", friendlyName: filtersTranslate[language].Statusproblematike, type: "custom", varType: "number", data: Object?.keys(dealSaveStatusCodes)?.map(item => {
                    //         return { text: dealSaveStatusCodes[item], value: item }
                    //     })
                    // },
                    { name: "isRecurring", friendlyName: filtersTranslate[language]["Produženje"], type: "boolean" },
                    { name: "isRecurringWithGuarantee", friendlyName: filtersTranslate[language]["Produženje s garancijom"], type: "boolean" },
                    { name: "ScheduledCallTime", friendlyName: filtersTranslate[language]["Datum ponovnog poziva"], type: "date" },
                    { name: "createdAt", friendlyName: filtersTranslate[language]["Datum kreiranja"], type: "date" },
                    { name: "updatedAt", friendlyName: filtersTranslate[language]["Datum poslednje izmjene"], type: "date" },
                    { name: "agentUpdatedAt", friendlyName: filtersTranslate[language]["Datum posljednje obrade"], type: "date" },
                    { name: "CallAttempts", friendlyName: filtersTranslate[language]["Ukupni broj poziva"], type: "number" },
                    { name: "isSpam", friendlyName: filtersTranslate[language]["Spam"], type: "boolean" },
                    { name: "isPlarityBrand", friendlyName: filtersTranslate[language]["Plarity brand"], type: "boolean" },
                    { name: "DealMoneyReceived", friendlyName: filtersTranslate[language].Dealrazdužen, type: "boolean" },
                    { name: "WarningSent", friendlyName: filtersTranslate[language].Opomenaposlana, type: "boolean" },
                    {
                        name: "isRecurringPaymentPaid", friendlyName: filtersTranslate[language].Odgođenoplaćanje, type: "custom", varType: "custom", data: [
                            { text: "Nema", value: null },
                            { text: "1 rata plaćena", value: false },
                            { text: "2 rata plaćena", value: true }
                        ]
                    },
                    { name: "AdditionalData:OriginID", friendlyName: "Scalelead-LeadID", type: "string" },
                    { name: "AdditionalData:PublisherID", friendlyName: "Scalelead-PublisherID", type: "string" },
                    { name: "AdditionalData:AdvertiserID", friendlyName: "Scalelead-AdvertiserID", type: "string" },
                    { name: "AdditionalData:OfferID", friendlyName: "Scalelead-OfferID", type: "string" },
                    { name: "AdditionalData:TakenOfferID", friendlyName: "Scalelead-TakenOfferID", type: "string" },
                    { name: "AdditionalData:AffiliateSubID", friendlyName: "CRM-AffiliateSubID", type: "string" }
                ]} />


        </div>
        {noDataMsg && <p style={{ color: "white", fontFamily: "Roboto" }}>{noDataMsg}</p>}
        <div className="component__dealReport__kpis">
            <div>
                <p>Total sales</p>
                <p>{spinner ? <Spinner style={{ width: "20px", height: "16px" }} color={"white"} /> : deals}</p>
            </div>
            {/* <div>
                <p >Average per hour </p>
                <p >{spinner ? <Spinner style={{ width: "20px", height: "16px" }} color={"white"} /> : (deals / 8).toFixed(1)}
                </p>
            </div> */}
            <div>
                <p>Revenue generated</p>
                <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : Number(total.toFixed(2)).toLocaleString()} €</p>
            </div>
            {/* <div>
                <p>Revenue delivered</p>
                <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : Number(revenueDelivered.toFixed(2)).toLocaleString()} €</p>
            </div> */}
            <div>
                <p>Average revenue</p>
                <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : !isNaN(prosjek) ? Number(prosjek.toFixed(2)).toLocaleString() : 0} €</p>
            </div>
            <div>
                <p>Average per agent daily</p>
                {agentsWD ? <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : (() => {
                    let total = 0;
                    for (let lds of Object.keys(agentsWD)) {
                        if (agentsWD[lds][0] > 0 && agentsWD[lds][1] > 0) {
                            total += agentsWD[lds][0] / agentsWD[lds][1];
                        }
                    }
                    return (total / Object.keys(agentsWD).length).toFixed(2);
                })()}</p> : <p></p>}
            </div>
            <div>
                <p>Average revenue per agent daily</p>
                {agentsWD ? <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : (() => {
                    let total = 0;
                    for (let lds of Object.keys(agentsWD)) {
                        if (agentsWD[lds][2] > 0 && agentsWD[lds][1] > 0) {
                            total += agentsWD[lds][2] / agentsWD[lds][1];
                        }
                    }
                    return (total / Object.keys(agentsWD).length).toFixed(2);
                })()}</p> : <p></p>}
            </div>
            {/* <div>
                <p>Delivery rate</p>
                <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : Number(dr.toFixed(2)).toLocaleString()} %</p>
            </div>
            <div>
                <p>CPAO</p>
                <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : Number(Number(avgCPAO).toFixed(2)).toLocaleString()} €</p>
            </div> */}
        </div>


        <div className="component__dealReport__content">
            {window.location.hash.startsWith("#/DealReport") ? <>

            </> : <>
                <div className="component__dealReport__files__buttons">
                    <p className={activeChart === "total" ? "component__dealReport__files__buttons__single component__dealReport__files__buttons__single-active" :
                        "component__dealReport__files__buttons__single"} onClick={() => {
                            setActiveChart("total")
                        }} >Deals created</p>
                    <p className={activeChart === "average" ? "component__dealReport__files__buttons__single component__dealReport__files__buttons__single-active" :
                        "component__dealReport__files__buttons__single"} onClick={() => {
                            setActiveChart("average")
                        }}>Average sales</p>
                    <p className={activeChart === "sum" ? "component__dealReport__files__buttons__single component__dealReport__files__buttons__single-active" :
                        "component__dealReport__files__buttons__single"} onClick={() => {
                            setActiveChart("sum")
                        }}>Total sales</p>
                </div>
                <div className="component__dealReport__files">

                    {activeChart === "total" && <>
                        {callcentersData && (

                            <Line
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    interaction: {
                                        mode: 'index',
                                        axis: 'x',
                                        intersect: false
                                    }


                                }}
                                data={{
                                    datasets: Object.keys(callcentersData).map((item, index) => {
                                        return {
                                            label: item,
                                            tension: 0.2,
                                            barThickness: 10,
                                            data: callcentersData[item].map(item => {
                                                let tmp = item[1].toFixed(2)
                                                if (isNaN(tmp)) return 0
                                                return tmp
                                            }),
                                            borderColor: headerColors("deal")[index],
                                            fill: headerColors("deal")[index],
                                            pointRadius: 3
                                        }
                                    }),
                                    labels: dealHours.map(item => {
                                        return item[0]
                                    }),
                                }}
                            />

                        )}
                    </>}
                    {activeChart === "average" && <>
                        {callcentersData && (

                            <Line
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    interaction: {
                                        mode: 'index',
                                        axis: 'x',
                                        intersect: false
                                    }

                                }}
                                data={{
                                    datasets: Object.keys(callcentersData).map((item, index) => {
                                        return {
                                            label: item,
                                            tension: 0.2,
                                            data: callcentersData[item].map(item => {
                                                let tmp = (item[2] / item[1]).toFixed(2)
                                                if (isNaN(tmp)) return 0
                                                return tmp
                                            }),
                                            borderColor: headerColors("deal")[index],
                                            fill: headerColors("deal")[index],
                                            pointRadius: 3
                                        }
                                    }),
                                    labels: dealHours.map(item => {
                                        return item[0]
                                    }),
                                }}
                            />

                        )}
                    </>}
                    {activeChart === "sum" && <>
                        {callcentersData && (

                            <Line
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    interaction: {
                                        mode: 'index',
                                        axis: 'x',
                                        intersect: false
                                    }

                                }}
                                data={{
                                    datasets: Object.keys(callcentersData).map((item, index) => {
                                        return {
                                            label: item,
                                            tension: 0.2,
                                            barThickness: 10,
                                            data: callcentersData[item].map(item => {
                                                let tmp = (item[2]).toFixed(2)
                                                if (isNaN(tmp)) return 0
                                                return tmp
                                            }),
                                            borderColor: headerColors("deal")[index],
                                            fill: headerColors("deal")[index],
                                            pointRadius: 3
                                        }
                                    }),
                                    labels: dealHours.map(item => {
                                        return item[0]
                                    }),
                                }}
                            />

                        )}
                    </>}

                </div>
            </>}


            <div className="component__dealReport__agentList">
                <h1 style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "50px"
                }}>Created deals per agent
                    <p style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px"
                    }}>
                        Sort by:
                        <Dropdown theme='dark' style={{
                            borderRadius: "50px",
                            backgroundColor: "#2e2e41",
                            boxShadow: "none"
                        }} data={[
                            { name: "Deal count", value: "broj" },
                            { name: "Total revenue", value: "promet" },
                            { name: "Average revenue", value: "prosjek" },
                        ]}
                            selected={0}
                            onChange={(e) => {
                                setSorting(e?.value)
                            }} />
                    </p>

                </h1>
                <div className='component__dealReport__agentList__single component__dealReport__agentList__single2' style={{
                    gridTemplateColumns: "150px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",

                }}  >
                    <p></p>
                    <p>Agent</p>
                    <p>Call centar</p>
                    <p>Total deals</p>
                    <p>Revenue</p>
                    <p>Average revenue</p>

                    <p>Average per hour</p>
                    <p>Average per day</p>
                    <p>Working days</p>
                    <p>Vrijeme u pozivu</p>
                </div>


                {(agents && callTime?.status === "ok") && (() => {
                    let tmp = [];
                    Object.keys(agents).map((agent, index) => {
                        if (selectedcc === null || selectedcc === agents[agent][1]) {
                            tmp.push([agent, ...agents[agent]])
                        }

                    })
                    if (sorting === "broj") {
                        tmp.sort(function (a, b) {
                            return b[1] - a[1];
                        })
                    } else if (sorting === "promet") {
                        tmp.sort(function (a, b) {
                            return b[3] - a[3];
                        })
                    } else if (sorting === "prosjek") {
                        tmp.sort(function (a, b) {
                            return b[3] / b[1] - a[3] / a[1];
                        })
                    }

                    return tmp.map((elem, index) => {
                        let tmpcalltime = callTime.data.find(call => call.Username === elem[0]);
                        if (!tmpcalltime) {
                            tmpcalltime = { UserID: agentsWD[elem[0]][1], lead: 0, deal: 0, dial: 0 };
                        }
                        return <AgentRow let hours={dealHours} elem={elem} index={index} agentsWD={agentsWD} selectedPeriod={selectedPeriod} callTime={(tmpcalltime?.lead + tmpcalltime?.deal + tmpcalltime?.dial) / 3600000} />
                    })
                })()}
                {agents && <div className='component__dealReport__agentList__single component__dealReport__agentList__single2' style={{
                    gridTemplateColumns: "150px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                }}  >
                    <p>Total</p>
                    <p>{Object.keys(agents).length}</p>
                    <p>-</p>
                    <p>{spinner ? <Spinner style={{ width: "20px", height: "16px" }} color={"white"} /> : deals}</p>
                    <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : Number(total.toFixed(2)).toLocaleString()} €</p>
                    <p>{spinner ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : !isNaN(prosjek) ? Number(prosjek.toFixed(2)).toLocaleString() : 0} €</p>
                    <p>-</p>
                    {(() => {
                        let tmp = [];
                        Object.keys(agents).map((agent, index) => {
                            if (selectedcc === null || selectedcc === agents[agent][1]) {
                                tmp.push([agent, ...agents[agent]])
                            }

                        })
                        let hours = 0;
                        let deals = 0;
                        let workingDays = 0;
                        let users = []
                        for (let elem of tmp) {
                            users.push(elem[1]);
                            hours += elem[1];
                            if (agentsWD && agentsWD[elem[0]][0] !== 0 && agentsWD[elem[0]][1] !== 0) {
                                workingDays += agentsWD[elem[0]][1];
                                deals += agentsWD[elem[0]][0];
                            }
                        }
                        let callTimeTotal = 0;
                        for (let call of callTime.data) {
                            callTimeTotal += call.lead;
                            callTimeTotal += call.deal;

                        }
                        const getDuration = (dur) => {
                            dur = Math.floor(dur / 1000);
                            let hours = 0;
                            let minutes = 0;
                            let seconds = 0;
                            while (dur >= 60) {
                                minutes += 1;
                                dur -= 60;

                                if (minutes === 60) {
                                    hours += 1;
                                    minutes -= 60;
                                };
                            };
                            hours = String(hours);
                            minutes = String(minutes);
                            seconds = String(dur);
                            if (hours.length === 1) hours = `0${hours}`;
                            if (minutes.length === 1) minutes = `0${minutes}`;
                            if (seconds.length === 1) seconds = `0${seconds}`;
                            return `${hours}:${minutes}:${seconds}`;
                        };


                        return <>


                            <p>{Number(deals / workingDays).toFixed(2)}</p>
                            <p>{workingDays}</p>
                            <p>{getDuration(callTimeTotal)}</p>
                        </>

                    })()}
                </div>}
            </div>
            <div className="component__dealReport__agentList" style={{
                marginTop: "10px"
            }}>
                <h1>Created deals per hour
                </h1>
                <div className='component__dealReport__agentList__single component__dealReport__agentList__single2' style={{
                    gridTemplateColumns: "150px 1fr 1fr 1fr 1fr"
                }} >
                    <p>Period</p>
                    <p>Total deals</p>
                    <p>Average revenue</p>
                    <p>Hourly revenue</p>
                    <p>Total revenue</p>
                </div>
                {dealHours &&

                    dealHours.map((item, index) => {
                        if (item[1] > 0 && item[2] > 0) {
                            let tmp = item[2] / item[1].toFixed(2)
                            if (isNaN(tmp)) tmp = 0

                            return <div className='component__dealReport__agentList__single' style={{
                                gridTemplateColumns: "150px 1fr 1fr 1fr 1fr",
                            }} >
                                <p>{item[0]} h</p>
                                <p style={{ cursor: "pointer", filter: "brightness(0.9)" }} onClick={(e) => {
                                    animateBox(e, <HoursPerAgent data={item} agents={agents} />)
                                }}>{item[1]}</p>
                                <p>{tmp.toFixed(2)} €</p>
                                <p>{item[2].toFixed(2)} €</p>
                                <p>{(() => {
                                    let tr = 0;
                                    dealHours.map((e, i) => {
                                        if (i <= index) {
                                            tr += e[2];
                                        }
                                    })
                                    return tr.toFixed(2);
                                })()} €</p>
                            </div>
                        }

                    })
                }
            </div>

            <div className="component__dealReport__agentList" style={{
                marginTop: "10px"
            }}>
                <h1>Offer score (0 - 5)
                </h1>
                <div className='component__dealReport__agentList__single component__dealReport__agentList__single2' style={{
                    gridTemplateColumns: "500px 1fr 1fr 1fr 1fr"
                }} >
                    <p>Offer</p>
                    <p>ADP (fixed)</p>
                    <p>ADP (real)</p>
                    <p>DR</p>
                    <p>Score</p>
                </div>

                {offerScores && Object.keys(offerScores).sort((a, b) => {
                    if (offerScores[a].Score < offerScores[b].Score) return 1;
                    if (offerScores[a].Score === offerScores[b].Score) {
                        if (offerScores[a].ADPCalculated < offerScores[b].ADPCalculated) return 1;
                    };
                    return -1;
                }).map(key => {
                    let curOffer = offers.find(o => o[1] === key);
                    if (!curOffer) curOffer = [];

                    return <div className='component__dealReport__agentList__single' style={{
                        gridTemplateColumns: "500px 1fr 1fr 1fr 1fr",
                        cursor: "pointer"
                    }} onClick={e => animateBox(e, <LeadKanban leads={offerScores[key].IDs} style={{ gridColumn: "1 / span all" }} />)} >
                        <p>{curOffer[0]}</p>
                        <p>45.00 €</p>
                        <p>{Number(offerScores[key].ADP).toFixed(2)} €</p>
                        <p>{Number(offerScores[key].DR).toFixed(2)} %</p>
                        <p>{Number(offerScores[key].Score).toFixed(0)}</p>
                    </div>
                })}

            </div>
            <p style={{ height: "10px" }}></p>
        </div>



    </div>
};

export default DealReport;



const LeadKanban = (props) => {
    return <div style={{
        all: "initial",
        width: '100%',
        height: '100%',
        display: "grid",
        gridTemplateRows: "40px max-content",
        gridTemplateColumns: "1fr",
        ...(props.style ?? {})
    }}>
        <CustomButtonSmall accent="#2b2c3e" value="X" style={{ marginLeft: "auto", border: "1px solid rgb(233, 86, 86)" }} onClick={props.onClose} />
        <Kanban
            type='deal'
            headersColors={headerColors("deal")}
            customFilters={[{
                name: "ID",
                op: "in",
                value: props.leads
            }]}
        /></div>;
};

const AgentRow = (props) => {
    const getDuration = (dur) => {
        dur = Math.floor(dur / 1000);
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        while (dur >= 60) {
            minutes += 1;
            dur -= 60;

            if (minutes === 60) {
                hours += 1;
                minutes -= 60;
            };
        };
        hours = String(hours);
        minutes = String(minutes);
        seconds = String(dur);
        if (hours.length === 1) hours = `0${hours}`;
        if (minutes.length === 1) minutes = `0${minutes}`;
        if (seconds.length === 1) seconds = `0${seconds}`;
        return `${hours}:${minutes}:${seconds}`;
    };



    return <div key={props.index} className='component__dealReport__agentList__single' style={{
        gridTemplateColumns: "150px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        filter: ((Number(props.callTime) < 2 * props.agentsWD[props.elem[0]][1] || props.elem[1] < 5) && moment(props.selectedPeriod[0].startDate).format("DD.MM.YYYY") !== moment().format("DD.MM.YYYY")) ? "sepia(1) saturate(5) hue-rotate(10deg)" : "none"
    }} >

        <p>{props.index + 1}</p>
        <p onClick={(e) => {
            animateBox(e, <LeadKanban leads={props.elem[4]} />)
        }} style={{ cursor: "pointer", filter: "brightness(0.9)" }}>{props.elem[0]}</p>
        <p>{props.elem[2]}</p>
        <p onClick={(e) => {
            animateBox(e, <AgentHours data={props.elem} />)
        }} style={{ cursor: "pointer", filter: "brightness(0.9)" }}>{props.elem[1]}</p>
        <p>{(props.elem[3]).toFixed(2)} €</p>
        <p>{(props.elem[3] / props.elem[1]).toFixed(2)} €</p>
        <p>{(props.elem[1] / 8).toFixed(2)}</p>
        <p>{(props.agentsWD && props.agentsWD[props.elem[0]][0] !== 0 && props.agentsWD[props.elem[0]][1] !== 0) ? (props.agentsWD[props.elem[0]][0] / props.agentsWD[props.elem[0]][1]).toFixed(2) : "-"}</p>
        <p>{props.agentsWD ? props.agentsWD[props.elem[0]][1] : ""}</p>
        <p>{getDuration(Number(props.callTime) * 3600000)}</p>
    </div>
}

const AgentHours = (props) => {



    return <div key={props.index} className='component__dealReport__agentList__singleHours'  >
        <div className="component__dealReport__agentList__singleHours__container">


            <p>Prodaje po satima -  {props.data[0]}</p>
            <div className='component__dealReport__agentList__single component__dealReport__agentList__single2' style={{
                gridTemplateColumns: "150px 1fr 1fr 1fr 1fr"
            }} >
                <p>Period</p>
                <p>Total deals</p>
                <p>Average revenue</p>
                <p>Hourly revenue</p>
                <p>Total revenue</p>
            </div>
            {props.data[5].map((item, index) => {
                if (item[1] > 0 && item[2] > 0) {
                    let tmp = item[2] / item[1].toFixed(2)
                    if (isNaN(tmp)) tmp = 0

                    return <div className='component__dealReport__agentList__single' style={{
                        gridTemplateColumns: "150px 1fr 1fr 1fr 1fr",
                    }} >
                        <p>{item[0]} h</p>
                        <p>{item[1]}</p>
                        <p>{tmp.toFixed(2)} €</p>
                        <p>{item[2].toFixed(2)} €</p>
                        <p>{(() => {
                            let tr = 0;
                            props.data[5].map((e, i) => {
                                if (i <= index) {
                                    tr += e[2];
                                }
                            })
                            return tr.toFixed(2);
                        })()} €</p>
                    </div>
                }

            })}
            <button onClick={props.onClose}>Zatvori</button>
        </div>


    </div>
}


const HoursPerAgent = (props) => {


    return <div key={props.index} className='component__dealReport__agentList__singleHours'  >
        <div className="component__dealReport__agentList__singleHours__container">


            <p>Prodaje za vrijeme -  {props.data[0]}</p>
            <div className='component__dealReport__agentList__single component__dealReport__agentList__single2' style={{
                gridTemplateColumns: "150px 1fr 1fr 1fr"
            }} >
                <p>Agent</p>
                <p>Total deals</p>
                <p>Average revenue</p>
                <p>Hourly revenue</p>
            </div>
            {Object.keys(props.agents).map((item, index) => {
                return <div className='component__dealReport__agentList__single' style={{
                    gridTemplateColumns: "150px 1fr 1fr 1fr ",
                }} >
                    <p>{item}</p>
                    {props.agents[item][4] ? props.agents[item][4].map(hour => {
                        if (hour[0] === props.data[0]) {
                            return <>
                                <p>{hour[1]}</p>
                                <p>{Number(hour[2]/hour[1]).toFixed(2)} €</p>
                                <p>{Number(hour[2]).toFixed(2)} €</p>
                            </>
                        }
                    }) : <><p>0</p><p>0</p><p>0</p></>}

                </div>
                // if (item[1] > 0 && item[2] > 0) {
                //     let tmp = item[2] / item[1].toFixed(2)
                //     if (isNaN(tmp)) tmp = 0

                //     return <div className='component__dealReport__agentList__single' style={{
                //         gridTemplateColumns: "150px 1fr 1fr 1fr 1fr",
                //     }} >
                //         <p>{item[0]} h</p>
                //         <p>{item[1]}</p>
                //         <p>{tmp.toFixed(2)} €</p>
                //         <p>{item[2].toFixed(2)} €</p>
                //         <p>{(() => {
                //             let tr = 0;
                //             props.data[5].map((e, i) => {
                //                 if (i <= index) {
                //                     tr += e[2];
                //                 }
                //             })
                //             return tr.toFixed(2);
                //         })()} €</p>
                //     </div>
                // }

            })}
            <button onClick={props.onClose}>Zatvori</button>
        </div>


    </div>
}

